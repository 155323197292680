import React from "react";
import { useSelector } from "react-redux";
import { Section } from "@Zakat/components/SummarySection";
import { assetSchemaV2 } from "@Zakat/components/SummarySection/constants/assetSchema";
import { zakatSelector } from "@Zakat/store/zakatV2/zakat.selector";

const ZakatBreakdownV2: React.FC = () => {
  const zakat = useSelector(zakatSelector);

  return (
    <div>
      <Section schema={assetSchemaV2} data={zakat} />
    </div>
  );
};

export default ZakatBreakdownV2;
