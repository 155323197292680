import { RootStateV2 } from "../rootReducer";

export const neverLoadedZakatSelector = (state: RootStateV2) =>
  state.zakat.neverLoaded;

export const isLoadingZakatSelector = (state: RootStateV2) =>
  state.zakat.loading;

export const zakatSelector = (state: RootStateV2) => state.zakat.data;

export const nisabSelector = (state: RootStateV2) => state.zakat.nisab;

export const zakatAssetsSelector = (state: RootStateV2) =>
  state.zakat?.data?.assets;

export const zakatLiabilitiesSelector = (state: RootStateV2) =>
  state.zakat?.data?.liabilities;

export const zakatTopUpSelector = (state: RootStateV2) => state.zakat.topUp;

export const zakatPaymentAmountSelector = (state: RootStateV2) =>
  state.zakat.paymentAmount;
